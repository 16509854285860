<template>
    <div class="relative">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="space-top lbplus-promos-banner-container w-full">
            <div class="relative h-full w-full flex justify-center items-end flex-col">
                <img class="absolute top-0 left-0 h-full object-cover z-5 -ml-10 md:ml-0 lg:ml-20 2xl:ml-16" src="../../../assets/images/home-page/lbplus-promos/lbplus-promos-banner.png" alt="Promos Banner">
                <div class="flex flex-col -mb-4 mobileL:mb-0 z-10 max-w-xs md:max-w-xl 2xl:max-w-3xl p-0 mobileL:p-4 mobileL:-mt-16 mobileL:mr-12 text-start mobileL:text-center ml-28 lg:ml-16 2xl:ml-20">
                    <h1 class="lbplus-promos-banner-title flex justify-start mobileL:justify-center mobileS:text-2xl xs:text-3xl md:text-5xl lg:text-6xl mobileL:ml-0 mb-2 mobileL:mb-0 text-white-1 leading-snug">
                        {{ titleText }}
                    </h1>
                    <p class="font-montserrat mobileL:mt-4 text-sm md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl mr-4 mobileL:px-10 mobileL:mr-0 text-white-1 leading-none mobileL:leading-normal">
                        We love offering special (and even exclusive) promos from 
                        time-to-time! Scroll down to know the best deals and 
                        discounts you can snag NOW!
                    </p>
                </div>
            </div>
        </div>
        <div class="relative">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/home-page/lbplus-promos/banner.png" alt="Background Image">
            <div class="relative flex items-center justify-center">
                <img class="mobileL:mr-4 w-1/2 my-12" src="../../../assets/images/home-page/lbplus-promos/ribbon.png" alt="Ribbon">
                <p class="text-green-12 font-montserrat mobileL:font-semibold w-2/3 mobileL:w-1/3 text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl text-center mobileL:my-0 my-8">
                We love offering special (and even exclusive) promos from time-to-time! 
                Get a FREE eyebrow threading and a 15% off when you avail any of our waxing 
                and threading services on your birth month!
                </p>
            </div>
        </div>
        <div class="bg-pink-12 py-2 mobileL:py-12 text-white-1">
            <div class="flex flex-col items-center justify-center text-center">
                <h2 class="font-mermaid text-2xl md:text-6xl lg:text-7xl 2xl:text-9xl">Frequency Card Reward</h2>
                <p class="mobileL:w-5/6 mobileL:mt-8 mx-6 mobileL:mx-0 text-xs leading-snug mobileL:leading-normal md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl">
                    For a minimum spend of P300.00 per visit; we'll give you a sticker, 
                    a stamp and a signature on each frequency card box to verify your visit. 
                    You'll get P100.00 OFF on your 5th visit and P200.00 OFF on your 10th visit
                </p>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybarePlusFooter.vue'

export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        titleText() {
            return this.windowWidth < 480 ? "Promo" : "Promos and Discounts";
        },
    },
    mounted() {
        this.$gtag.pageview('/laybareplus/promos'),
        window.addEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
}
</script>

<style>
.lbplus-promos-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #488c7d 0%, #2B6D5F 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .lbplus-promos-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

.lbplus-promos-banner-title {
    color: #FFF;
    text-align: center;
    font-family: Mermaid;
    font-style: normal;
    font-weight: 600;
}

@media (min-width: 1536px) {
    .lbplus-promos-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .lbplus-promos-banner-container{
    height:255px;
    }
    .space-top {
    margin-top: 60px;
    }
}
</style>